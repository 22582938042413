//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EventTable from '@/components/EventList/EventTable.vue';
import { i18n } from '@/services/i18n.init';

export default {
  name: 'DuplicateEventModal',
  props: ['eventList'],
  components: {
    EventTable,
  },
  data() {
    return {
      duplicateEventsModalColumns: [
        {
          name: 'dateFrom',
          label: i18n.tc('events.my.header.date'),
          align: 'center',
          sortable: true,
        },
        {
          name: 'image',
          label: i18n.tc('events.my.header.image'),
          align: 'center',
        },
        {
          name: 'title',
          label: i18n.tc('events.my.header.title'),
          align: 'left',
          sortable: true,
        },
        {
          name: 'venue',
          label: i18n.tc('events.my.header.venue'),
          align: 'center',
          sortable: true,
        },
        {
          name: 'attendanceType',
          label: i18n.tc('events.my.header.privacy'),
          align: 'center',
          sortable: true,
        },
      ],
    };
  },
  methods: {},
  computed: {},
  watch: {},
  mounted() {},
  created() {},
};
